import React, { createContext, useReducer, useMemo } from 'react';

export const MonitoringCtx = createContext();

const initialState = {
  selectedId: null,
  editModalOpen: false,
  deleteModalOpen: false,
  elasticData: {}
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SELECTED_ID':
      return {
        ...state,
        selectedId: action.payload,
      };
    case 'SET_EDIT_MODAL_OPEN':
      return {
        ...state,
        editModalOpen: action.payload,
      };
    case 'SET_DELETE_MODAL_OPEN':
      return {
        ...state,
        deleteModalOpen: action.payload,
      };
    case 'SET_ELASTIC_DATA':
      return {
        ...state,
        elasticData: {
          ...state.elasticData,
          ...action.payload
        },
      };
    default:
      return state;
  }
}

export function MonitoringCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <MonitoringCtx.Provider value={value}>{children}</MonitoringCtx.Provider>;
}

