import { useEffect } from 'react';

import offerEn from 'images/offers/offer_en.png';
import offerEs from 'images/offers/offer_es.png';
import useOpenPricing from 'assets/CustomHooks/use-open-pricing';
import OfferCloseIcon from 'components/Icons/OfferCloseIcon';
import './offer-modal.scss';

const { OFFERS_CAMPAIGNS } = require('../../utils/offers.json')

export default function OfferModal({ open, toggleVisibility }) {

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  const lang = JSON.parse(localStorage.getItem('user'))?.user?.lang;
  const openPricing = useOpenPricing();

  const image = lang === 'es' 
    ? offerEs 
    : offerEn;

  const activeOffer = (OFFERS_CAMPAIGNS.find((offer) => offer.active))?.name;

  return open ? (
    <>
      <div className='modal-backdrop' />
      <div
        className="modal"
        onClick={() => {
          toggleVisibility();
          openPricing({ button_name:`${activeOffer}_modal` })
        }}
        style={{
          backgroundImage: `url(${image})`, 
          backgroundSize: 'contain', 
          backgroundPosition: 'center' 
        }}
      >
        <div className="modal-close-icon" onClick={(e) => {
          e.stopPropagation();
          toggleVisibility();
        }}>
          <OfferCloseIcon width={28} height={28} fill='#6f7585' />
        </div>
      </div>
    </>
  ) : null;
}