import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import useOpenPricing from 'assets/CustomHooks/use-open-pricing';

export default function UpgradeModal({ visible, toggleVisibility, type, amplitudeInfo }) {
  const { t } = useTranslation('common');
  const openPricing = useOpenPricing();

  return (
    <Modal
      className="account-stats-modal -no-padding"
      open={visible}
      onCancel={(e) => toggleVisibility(e)}
      title={t('upgrade.modal.title')}
      centered
      okText={t('upgrade.modal.button')}
      okButtonProps={{
        id: 'proposal-create-opp',
        onClick: (e) => {
          toggleVisibility(e);
          openPricing(amplitudeInfo)
        },
      }}
      cancelButtonProps={ {
        id: 'proposal-create-opp-cancel',
        onClick: (e) => toggleVisibility(e),
      }}
      cancelText={t('pipelines.card_content.files_modal.cancel')}
    >
      {type === 'invite' ? (
        <p>{t('upgrade.modal.invite')}</p>
      ) : (
        <p>{t('upgrade.modal.text')}</p>
      )}
    </Modal>
  );
}
