import { uniqBy } from 'lodash';
import React, { createContext, useReducer, useMemo } from 'react';

export const PipelinesCtx = createContext();

const initialState = {
  checkStatus: [],
  pageChecked: false,
  allChecked: false,
  pipelineCreatorsContactInfo: [],
  profilesData: [],
  pipelineCreatorsUnlockInfo: [],
  cardsUnlockedCreatorStatus: {},
  cardsContactStatus: {},
  moonioIdsFromProfileIds: {},
  pipelines: [],
  pipelinesFetched: false,
  pipeline: null,
  taskIsDraggable: true,
  taskIsDragging: false
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CHECKED_STATUS':
      return {
        ...state,
        checkStatus: action.payload,
      };
    case 'SET_PAGE_CHECKED':
      return {
        ...state,
        pageChecked: action.payload,
      };
    case 'SET_ALL_CHECKED':
      return {
        ...state,
        allChecked: action.payload,
      };
    case 'ADD_CREATORS_CONTACT_INFO':
      return {
        ...state,
        pipelineCreatorsContactInfo: uniqBy([
          ...state.pipelineCreatorsContactInfo,
          ...action.payload,
        ],
          'task_id'),
      };
    case 'ADD_PROFILES_DATA':
      return {
        ...state,
        profilesData: uniqBy([
          ...state.profilesData,
          ...action.payload,
        ],
          'elastic_id'),
      }
    case 'ADD_CREATORS_UNLOCK_INFO':
      return {
        ...state,
        pipelineCreatorsUnlockInfo: uniqBy([
          ...state.pipelineCreatorsUnlockInfo,
          ...action.payload,
        ],
          'elastic_id'),
      };
    case 'FLUSH_CREATORS_CONTACT_INFO':
      return {
        ...state,
        pipelineCreatorsContactInfo: [],
      };
    case 'FLUSH_CREATORS_UNLOCK_INFO':
      return {
        ...state,
        pipelineCreatorsUnlockInfo: [],
      };
    case 'ADD_CARDS_UNLOCKED_CREATOR_STATUS':
      return {
        ...state,
        cardsUnlockedCreatorStatus: {
          ...state.cardsUnlockedCreatorStatus,
          ...action.payload,
        },
      };
    case 'ADD_CARDS_CONTACT_STATUS':
      return {
        ...state,
        cardsContactStatus: {
          ...state.cardsContactStatus,
          ...action.payload,
        },
      };
    case 'SET_CARDS_DRAGGABLE':
      return {
        ...state,
        taskIsDraggable: action.payload,
    }
    case 'SET_CARDS_DRAGGING':
      return {
        ...state,
        taskIsDragging: action.payload,
    }
    case 'ADD_CARDS_MOONIO_IDS':
      return {
        ...state,
        moonioIdsFromProfileIds: {
          ...state.moonioIdsFromProfileIds,
          ...action.payload,
        },
      };
    case 'SET_PIPELINES':
      return {
        ...state,
        pipelines: action.payload,
        pipelinesFetched: true,
      };
    case 'SET_PIPELINE':
      return {
        ...state,
        pipeline: action.payload,
      };
    default:
      return state;
  }
}

export function PipelinesCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <PipelinesCtx.Provider value={value}>{children}</PipelinesCtx.Provider>;
}

