import { useContext, useEffect, useState } from 'react';
import { Button, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import usersService from 'services/users.service';
import { UserDetails } from 'pages/SignupDetails/components/UserDetails';
import { RequestNetworkCtx } from './context/request-network-context';

import './request-networks-modal.scss';
import ExternalLinkIcon from 'components/Icons/ExternalLinkIcon';

export default function RequestNetworksModal() {
  const { t } = useTranslation('common');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  const { state, dispatch } = useContext(RequestNetworkCtx);

  const [isOpened, setIsOpened] = useState(user?.needs_networks);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'SET_IS_MODAL_OPENED',
      payload: isOpened,
    });
  }, [dispatch, isOpened]);

  const handleSubmission = async () => {
    const values = {
      name: state.name,
      instagram_username: state.igHandle,
      youtube_username: state.ytHandle,
      tiktok_username: state.ttHandle,
      cost_per_post_instagram: state.cost_per_post_instagram,
      cost_per_post_youtube: state.cost_per_post_youtube,
      cost_per_post_tiktok: state.cost_per_post_tiktok,
      cost_per_reels_instagram: state.cost_per_reels_instagram,
      cost_per_story_instagram: state.cost_per_story_instagram,
      currency_instagram: state.currency_instagram,
      currency_youtube: state.currency_youtube,
      currency_tiktok: state.currency_tiktok,
    };

    setIsLoading(true);
    const user = await usersService.sendSignupDetails(values);
    if (!user) {
      message.error(t('lists.error'));
      setIsLoading(false);
      return;
    }
    message.success(t('request-networks-modal.success'));
    setIsOpened(false);
    localStorage.setItem('user', JSON.stringify({ user }));
    setIsLoading(false);
  };

  const enableSubmitBtn =
    !!state.selectedNetworks.length &&
    state.name &&
    Object.values(state.isHandleInvalid).every((item) => item === false) &&
    ((state.igHandle && !state.igHandleHasError) ||
      (state.ytHandle && !state.ytHandleHasError) ||
      (state.ttHandle && !state.ttHandleHasError)) &&
    (!state.igHandle || !state.igHandleHasError) &&
    (!state.ytHandle || !state.ytHandleHasError) &&
    (!state.ttHandle || !state.ttHandleHasError);

  return (
    <Modal
      title={t('request-networks-modal.title')}
      open={isOpened}
      centered
      onCancel={() => setIsOpened(false)}
      width={450}
      className="request-networks-modal"
      okButtonProps={{ loading: isLoading, disabled: !enableSubmitBtn }}
      onOk={handleSubmission}
      okText={t('invite_modal.save')}
      cancelText={t('invite_modal.cancel')}
    >
      <div className="w-100 d-flex justify-content-end">
        <Button
          type="link"
          onClick={() => window.open(`${window.location.origin}/bookDemo`, '_blank')}
          style={{ marginBottom: -10, marginTop: -10, paddingRight: 0 }}
          className="pl-0 d-flex align-items-center"
          icon={<ExternalLinkIcon width={16} height={16} color={'#00A742'} />}
        >
          {t('navbar_unlogged.demo')}
        </Button>
      </div>
      <UserDetails />
    </Modal>
  );
}
