import React, { lazy, useEffect, useState } from 'react';

import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import RouterLayout from 'components/Layouts/RouterLayout';
import AppLayout from './components/Layouts/AppLayout/AppLayout';
import PublicRoute from './components/PublicRoute/PublicRoute';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { CreateOpportunityCtxProvider } from 'pages/CreateOpportunityPage/contexts/create-opportunity-ctx';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import PrintContainer from 'components/PrintContainer/PrintContainer';
import ShareContainer from 'components/ShareContainer/ShareContainer';
import { isMobile } from 'react-device-detect';

async function loadPolyfills() {
  await import('intersection-observer');
}
typeof window.IntersectionObserver === 'undefined' && loadPolyfills();

const Homepage = lazy(() => import('./pages/Homepage/Homepage'));
const CustomerUnsubscribe = lazy(() => import('./pages/Static/CustomerUnsubscribe'));
const UnsubscribeOptedOutCreatorFromInvites = lazy(() =>
  import('./pages/Static/UnsubscribeOptedOutCreatorFromInvites'),
);
const Activation = lazy(() => import('./pages/Static/Activation'));
const BookDemo = lazy(() => import('./pages/Static/BookDemo'));
const Reviews = lazy(() => import('./pages/Static/Reviews'));
const ReviewsThanksPage = lazy(() => import('./pages/Static/ReviewsThanksPage'));
const ExpiredReview = lazy(() => import('./pages/Static/ExpiredReview/ExpiredReview'));
const VerificationEmail = lazy(() => import('./pages/Static/VerificationEmail'));
const WeAreVerifyingPage = lazy(() => import('./pages/WeAreVerifyingPage/WeAreVerifyingPage'));
const WeAreVerifyingYtPage = lazy(() => import('./pages/WeAreVerifyingPage/WeAreVerifyingYtPage'));
const Opportunities = lazy(() => import('./pages/Opportunities/Opportunities'));
const Opportunity = lazy(() => import('./pages/Opportunity/Opportunity'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));
const PublicProfile = lazy(() => import('./pages/UserProfile/PublicProfile/PublicProfile'));
const AccountOpportunities = lazy(() => import('./pages/UserProfile/AccountOpportunities/AccountOpportunities'));
const Pricing = lazy(() => import('./pages/Pricing/Pricing'));
const MyCollaborations = lazy(() => import('./pages/MyCollaborations/MyCollaborations'));
const ProfileView = lazy(() => import('./pages/ProfileView/ProfileView'));
const ProfileViewPdf = lazy(() => import('./pages/ProfileView/ProfileViewPdf'));
const HardRefresh = lazy(() => import('./utils/Refresh'));
const AcceptSeat = lazy(() => import('pages/AcceptSeat/AcceptSeat'));
const Pipelines = lazy(() => import('pages/Pipelines/Pipelines'));
const Reporting = lazy(() => import('pages/Reporting/Reporting'));
const Report = lazy(() => import('pages/Reporting/components/Report/Report'));
const ReportPdfView = lazy(() => import('pages/Reporting/ReportPdfView'));
const ReportShare = lazy(() => import('pages/Reporting/ReportShare'));
const ReportsIndex = lazy(() => import('pages/Reporting/components/ReportsIndex/ReportsIndex'));
const CardView = lazy(() => import('pages/CardView/CardView'));
const MediaView = lazy(() => import('pages/MediaView/MediaView'));
const MonitoringView = lazy(() => import('pages/MonitoringView/MonitoringView'));
const PaymentsView = lazy(() => import('pages/PaymentsView/PaymentsView'));
const CreateOpportunityPage = lazy(() => import('./pages/CreateOpportunityPage/CreateOpportunityPage'));
const EditOpportunityPage = lazy(() => import('./pages/EditOpportunityPage/EditOpportunityPage'));
const ResultsContent = lazy(() => import('pages/Results/ResultsContent'));
const Invoices = lazy(() => import('pages/Invoices/Invoice'));
const RedirectListToBoard = lazy(() => import('utils/RedirectListToBoard'));
const Invites = lazy(() => import('pages/Invites/Invites'));
const SettingsContainer = lazy(() => import('pages/UserProfile/SettingsContainer/SettingsContainer'));
const Chat = lazy(() => import('./pages/Chat/Chat'));
const NewSubscription = lazy(() => import('./pages/NewSubscription/NewSubscription'));

const App = () => {
  const [isErrorPage, setIsErrorPage] = useState(false);

  useEffect(() => {
    const root = document.querySelector('#root');
    if (isMobile) {
      root.classList.add('-no-padding');
    } else {
      root.classList.remove('-no-padding');
    }
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RouterLayout isErrorPage={isErrorPage} setIsErrorPage={setIsErrorPage} />}>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <AppLayout>
                <Homepage />
              </AppLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PublicRoute>
              <AppLayout>
                <Homepage />
              </AppLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <AppLayout>
                <Homepage />
              </AppLayout>
            </PublicRoute>
          }
        />
        <Route path="/unsubscribe/:encodedDataJwt" element={<UnsubscribeOptedOutCreatorFromInvites />} />
        <Route path="/customer_unsubscribed" element={<CustomerUnsubscribe />} />
        <Route path="/activation/:userEncoded" element={<Activation />} />
        <Route path="/bookDemo" element={<BookDemo />} />
        <Route path="/reviews/:userEncoded/:proposalEncoded" element={<Reviews />} />
        <Route path="/reviewDone" element={<ReviewsThanksPage />} />
        <Route path="/reviewExpired" element={<ExpiredReview />} />
        <Route path="/verification" element={<VerificationEmail />} />
        <Route path="/subscription" element={<NewSubscription />} />
        <Route path="/accept_seat" element={<AcceptSeat />} />
        <Route path="/verify" element={<WeAreVerifyingPage />} />
        <Route path="/verify_yt" element={<WeAreVerifyingYtPage />} />
        <Route path="/opportunities/:id" element={<Opportunity />} />
        {['pdf/creators', 'pdf/brands', 'pdf/profile'].map((path) => (
          <Route
            key={path}
            path={`${path}/:id`}
            element={
              <PrintContainer isProfile>
                <ProfileViewPdf />
              </PrintContainer>
            }
          />
        ))}
        {['/creators', '/brands', 'profile'].map((path) => (
          <Route key={path} path={`${path}/:id`} element={<ProfileView />} />
        ))}
        <Route path="/opportunities/:id" element={<Opportunity />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route exact path="/password/:token" element={<ResetPassword />} />
        <Route
          path="/my_profile"
          element={
            <PrivateRoute>
              <PublicProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_settings"
          element={
            <PrivateRoute>
              <SettingsContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <PrivateRoute>
              <Invoices />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_opportunities"
          element={
            <PrivateRoute>
              <AccountOpportunities />
            </PrivateRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <PaymentsView />
            </PrivateRoute>
          }
        />
        <Route
          path="/pipelines"
          element={
            <PrivateRoute allowedUserTypes={['brand']}>
              <Pipelines />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute allowedUserTypes={['brand']}>
              <Reporting />
            </PrivateRoute>
          }
        >
          <Route index element={<ReportsIndex />} />
          <Route path=":reportId" element={<Report />} />
        </Route>
        <Route
          path="/reports/:reportId/pdf"
          element={
            <PrivateRoute>
              <PrintContainer>
                <ReportPdfView />
              </PrintContainer>
            </PrivateRoute>
          }
        />
        <Route
          path="/reports/:reportCode/share"
          element={
            <ShareContainer>
              <ReportShare />
            </ShareContainer>
          }
        />
        <Route
          path="/media"
          element={
            <PrivateRoute allowedUserTypes={['brand']}>
              <MediaView />
            </PrivateRoute>
          }
        />
        <Route
          path="/monitoring"
          element={
            <PrivateRoute allowedUserTypes={['brand']}>
              <MonitoringView />
            </PrivateRoute>
          }
        />
        <Route
          path="/card"
          element={
            <PrivateRoute allowedUserTypes={['brand']}>
              <CardView />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit_opportunity/:id"
          element={
            <PrivateRoute allowedUserTypes={['brand']}>
              <EditOpportunityPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/create_opportunity"
          element={
            <CreateOpportunityCtxProvider>
              <PrivateRoute allowedUserTypes={['brand']}>
                <CreateOpportunityPage />
              </PrivateRoute>
            </CreateOpportunityCtxProvider>
          }
        />
        <Route
          path="/my_collaborations"
          element={
            <PrivateRoute>
              <MyCollaborations />
            </PrivateRoute>
          }
        />
        <Route
          path="/collaborations"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route element={<ResultsContent />} path="/creators" />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/refresh" element={<HardRefresh />} />
        <Route path="/lists" element={<RedirectListToBoard />} />
        <Route
          path="/invites"
          element={
            <>
              <PrivateRoute allowedUserTypes={['brand']}>
                <Invites />
              </PrivateRoute>
            </>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>,
    ),
  );
  return <RouterProvider router={router} />;
};

export default App;
