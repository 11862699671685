import { useState } from 'react';
import { Modal, message, Typography, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import usersService from 'services/users.service';
import ExternalLinkIcon from 'components/Icons/ExternalLinkIcon';

import './request-brand-details-modal.scss';

const { Text } = Typography;
const { Option } = Select;

export default function RequestBrandDetails() {
  const { t } = useTranslation('common');
  const user = JSON.parse(localStorage.getItem('user'))?.user;

  const COMPANY_TYPES = ['Brand', 'E-Commerce', 'Agency', 'Other'];
  const COMPANY_SIZES = ['1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5001-10,000', '10,001+'];

  const [isOpened, setIsOpened] = useState(user?.needs_details && localStorage.getItem('closedTour'));
  const [isLoading, setIsLoading] = useState(false);
  const [companyType, setCompanyType] = useState(user?.company_type);
  const [companySize, setCompanySize] = useState(user?.company_size);

  const handleSubmission = async () => {
    const values = {
      company_type: companyType,
      company_size: companySize,
    };
    setIsLoading(true);
    const user = await usersService.sendSignupBrandDetails(values);
    if (!user) {
      message.error(t('lists.error'));
      setIsLoading(false);
      return;
    }
    message.success(t('request-networks-modal.success'));
    document.body.style.overflowY = 'scroll';
    setIsOpened(false);
    user.needs_details = false;
    localStorage.setItem('user', JSON.stringify({ user }));
    setIsLoading(false);
  };

  return (
    <Modal
      title={t('request-brand-details-modal.title')}
      open={isOpened}
      centered
      width={450}
      className="request-brand-details-modal"
      okButtonProps={{ loading: isLoading, disabled: !companyType || !companySize }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleSubmission}
      okText={t('invite_modal.save')}
      closable={false}
    >
      <div className="user-details">
        <div className="w-100 d-flex justify-content-end">
          <Button
            type="link"
            onClick={() => window.open(`${window.location.origin}/bookDemo`, '_blank')}
            style={{ marginBottom: -10, marginTop: -10, paddingRight: 0 }}
            className='pl-0 d-flex align-items-center'
            icon={<ExternalLinkIcon width={16} height={16} color={'#00A742'} />}
          >
            {t('navbar_unlogged.demo')}
          </Button>
        </div>
        <div className="user-details__name mb-5">
          <p className="request-networks-modal__label mb-2">
            <Text type="danger">
              <sup>*</sup>
            </Text>
            {t('signup_form.agency_type_placeholder')}
          </p>
          <Select
            className="flex-1 w-100"
            onChange={(v) => setCompanyType(v)}
            value={companyType}
            onDropdownVisibleChange={(open) => {
              document.body.style.overflow = 'hidden';
            }}
            placeholder={COMPANY_TYPES[0]}
          >
            {COMPANY_TYPES.map((k) => (
              <Option value={k} key={k}>
                {k}
              </Option>
            ))}
          </Select>
          <p className="request-networks-modal__label mb-2 mt-3">
            <Text type="danger">
              <sup>*</sup>
            </Text>
            {t('signup_form.agency_size_placeholder')}
          </p>
          <Select
            className="flex-1 w-100"
            onChange={(v) => setCompanySize(v)}
            value={companySize}
            onDropdownVisibleChange={(open) => {
              document.body.style.overflow = 'hidden';
            }}
            placeholder={COMPANY_SIZES[0]}
          >
            {COMPANY_SIZES.map((k) => (
              <Option value={k} key={k}>
                {k}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </Modal>
  );
}
